import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/pages/login'),
      hidden: true
    },

    {
      path: '/',
      name: 'home',
      component:  () => import('@/pages/home'),
      children: [
        // {
        //   path: 'index',
        //   name: '首页',
        //   component: () => import('@/pages/card'),
        //   meta: { title: '名片管理', icon: 'dashboard' }
        // },
        {
          path: 'article',
          name: 'article',
          component: () => import('@/pages/article')
        },
        {
          path: 'card',
          name: 'card',
          component: () => import('@/pages/card')
        },
        {
          path: 'certification',
          name: 'certification',
          component: () => import('@/pages/certification')
        },
        {
          path: 'content',
          name: 'content',
          component: () => import('@/pages/content')
        }
      ]
    },
    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/login', hidden: true }
  ]
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return VueRouterPush.call(this, location).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

export default router
