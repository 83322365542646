import request from '@/utils/request'

export function login(param) {
  return request({
    url: '/user/login',
    method: 'post',
    data: param
  })
}

export function getUserInfo() {
  return request({
    url: '/user/getCurrentUser',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'get'
  })
}

export function create(param) {
  return request({
    url: '/user/create',
    method: 'post',
    data: param
  })
}

export function updateStatus(param) {
  return request({
    url: '/user/updateStatus',
    method: 'post',
    data: param
  })
}

export function changePassword(param) {
  return request({
    url: '/user/ChangePassword',
    method: 'post',
    data: param
  })
}

//export function changeSelfPassword(param) {
//  return request({
//    url: '/user/changeSelfPassword',
//    method: 'post',
//    data: param
//  })
//} 

export function list(pageSize, pageIndex) {
  return request({
    url: '/user/list?role=User&pageSize=' + pageSize + "&pageIndex=" + pageIndex,
    method: 'get'
  })
}

export function del(param) {
  return request({
    url: '/user/delete',
    method: 'post',
    data: param
  })
}

export function verify(param) {
  return request({
    url: '/userVerify/Verify?userid=' + param.UserID + "&token=" + param.Token,
    method: 'get',
    data: param
  })
}
