import router from '@/router'
//import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
//import { getToken } from '@/utils/auth' // get token from cookie
//import getPageTitle from '@/utils/get-page-title'
import { verify } from '@/api/user'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  //// set page title
  //document.title = getPageTitle(to.meta.title)

  //determine whether the user has logged in
  //const hasToken = getToken()
  const hasToken = window.sessionStorage.getItem("token");
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    }
    else {
      try {
        next()
      } catch (error) {
        // remove token and go to login page to re-login
        window.sessionStorage.setItem("token", "");
        Message.error(error || 'Has Error')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
  /* has no token*/
  else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    }
    else {
      var userid = GetQueryString("userid");
      var token = GetQueryString("token");
      if (userid && token) {
        var verifyParam = {
          UserID: userid,
          Token: token
        };
        verify(verifyParam).then(response => {
          if (response && response.code == 0) {
            var user = response.data;
            window.sessionStorage.setItem("token", user.token);
            window.sessionStorage.setItem("UserId", user.userID);
            window.sessionStorage.setItem("UserName", user.alias);
            //window.sessionStorage.setItem("InnerUser", null);
            next(this.redirect || '/')
          } else {
            Message.error(response.message);
          }
        });
      }
      else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = decodeURI(window.location.search.substr(1)).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  else {
    return null;
  }
}
